var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c(
        "a-tabs",
        { attrs: { "default-active-key": "1" }, on: { change: _vm.callback } },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "试用期" } },
            [
              _vm.keyval === "1"
                ? _c("WorkerTabel", {
                    attrs: { keyval: _vm.keyval, "is-first": _vm.isFirst },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "最近转正" } },
            [
              _vm.keyval === "2"
                ? _c("WorkerTabel", { attrs: { keyval: _vm.keyval } })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }