// 人员模块-入转（转正）
import {
  USER
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 试用期人员列表
export async function probationList(params) {
  return request(`${USER}/transfer/applyOfficial/probationList`, METHOD.GET, params)
}

// 最近转正列表
export async function recentlyOfficialList(params) {
  return request(`${USER}/transfer/applyOfficial/recentlyOfficialList`, METHOD.GET, params)
}

// 调hr办理转正
export async function official(params) {
  return request(`${USER}/transfer/applyOfficial/official`, METHOD.POST, params)
}

// 调整转正日期
export async function editOfficialDate(params) {
  return request(`${USER}/transfer/applyOfficial/editOfficialDate`, METHOD.POST, params)
}

// 修改试用期
export async function editProbation(params) {
  return request(`${USER}/transfer/applyOfficial/editProbation`, METHOD.POST, params)
}

// 不予转正
export async function noOfficial(params) {
  return request(`${USER}/transfer/applyOfficial/noOfficial`, METHOD.POST, params)
}

// 试用期导出
export async function probationListExport(params) {
  return request(`${USER + '/transfer/applyOfficial/probationListExport'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 最近转正导出
export async function recentlyOfficialLIstExport(params) {
  return request(`${USER + '/transfer/applyOfficial/recentlyOfficialListExport'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
